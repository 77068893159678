import styled from 'styled-components';

export const PageContainer = styled.div`
    position: relative;
    max-width: 1295px;
    padding: 30px;
    margin: 0 auto;
    padding-top: 135px;
    z-index: 2;

    @media (max-width: 768px) {
        padding: 40px 27px 0; 
    }
`;

export const PageHeading = styled.h1`
    font-weight: 600;
    font-size: 55px;
    letter-spacing: 0.08em;
    line-height: 62px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;

    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;

    @media (max-width: 768px) {
        font-size: 25px;
        line-height: 28px;
    }
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 85px;
    width: 58%;

    @media (max-width: 768px) {
        width: 100%;
        gap: 16px;
    }
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 23px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 23px;

    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;
`;

export const Item = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
    gap: 18px;

    &::before {
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        background-color: #F0BB00;
        border-radius: 3px;
        transform: rotate(-60deg);
    }

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 18px;
        gap: 13px;

        &::before {
            width: 16px;
            height: 16px;
        }
    }
`;

export const StyledSpan = styled.span`
    max-width: 90%;

    @media (max-width: 768px) {
        max-width: 85%;
    }
`;

export const BigBtn = styled.a`
  text-align: center;
  text-decoration: none;
  color: #000000;
    box-sizing: border-box;
    display: block;
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    padding: 18px 55px 19px;
    background-color: #F0BB00;
    border: 0;
    border-radius: 16px;
    max-width: 539px;
    margin-left: 40px;
    cursor: pointer;

    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;

    &:hover {
        background-color: #AA8500;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 18px;
        padding: 8px 17px;
        margin-left: 30px;
        max-width: 235px;
    }
`;