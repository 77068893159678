export const VictorinItems = [
    {
        id: 0,
        text: '¿Quieres tener cientos de suscriptores?'
    },
    {
        id: 1,
        text: '¿Quieres aprender a diseñar correctamente tu red social?'
    },
    {
        id: 2,
        text: '¿Quieres saber cómo grabar videos de tendencias?'
    },
    {
        id: 3,
        text: '¿Quieres aprender a grabar y editar contenidos?'
    },
    {
        id: 4,
        text: '¿Quieres ganar mucho dinero colaborando con grandes marcas?'
    },
    {
        id: 5,
        text: '¿Estás listo para aprender a ganar más dinero?'
    },
]