import styled from 'styled-components';
import background from './images/bg.jpg';
import backgroundHouse from './images/house.svg';
import photoBanner from './images/home-banner-object-2.png';
import mobBack from './images/mobile-back.jpg';


export const StyledApp = styled.div`
    background-image: url(${background});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;

    @media (max-width: 768px) {
        background-image: url(${mobBack});
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 465px;
            height: 465px;
            background-image: url(${backgroundHouse});
            background-size: contain;
            background-repeat: no-repeat;
            bottom: 0;
            z-index: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 190px;
            height: 342px;
            background-image: url(${photoBanner});
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -30px;
            left: -10px;
            z-index: 0;
            
        }
    }
`;

export const Wrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 2;

    @media (max-width: 768px) {
        justify-content: center;
        align-items: flex-start;
    }
`;

export const BgBlur = styled.div`    

    @media (max-width: 768px) {
        display: block;
        position: relative;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(4.5px);
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;