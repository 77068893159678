import {React} from 'react';
import {BigBtn, Item, LinkWrapper, List, PageContainer, PageHeading, StyledSpan} from './styled';
import {useParams} from "react-router";

const FormLink = ({param}) => {
    return (
        <>
            <PageContainer>
                <PageHeading>¡Felicidades! Nuestro curso es ideal para ti.</PageHeading>
                <LinkWrapper>
                    <List>
                        <Item><StyledSpan>Curso de 2 semanas</StyledSpan></Item>
                        <Item><StyledSpan>Totalmente gratis</StyledSpan></Item>
                        <Item><StyledSpan>Toda la información que necesitas para hacerte popular</StyledSpan></Item>
                        <Item><StyledSpan>Acceso a la base de anunciantes una vez que hayas completado el curso para que puedas ganar dinero inmediatamente</StyledSpan></Item>
                    </List>
                    <BigBtn href={`https://app.academyblogger.site/?t1=l1${param ? '&t2=' + param : ''}`} target={'_blank'}>Comenzar los estudios</BigBtn>
                </LinkWrapper>
            </PageContainer>
        </>
    );
}

export default FormLink;
