import Start from './components/boxes/start/start';
import {BgBlur, StyledApp, Wrapper} from './styled';
import Victorin from './components/boxes/victorin/victorin';
import FormLink from './components/boxes/form-link/form-link';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {useEffect, useState} from "react";

function App() {
  const [param, setParam] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const t2Value = urlParams.get('t2');

    setParam(t2Value);
  }, []);



  return (
    <StyledApp>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Wrapper>
              <Start />
            </Wrapper>
          </Route>
          <Route path='/Victorin'>
            <Wrapper>
              <Victorin />
            </Wrapper>
          </Route>
          <Route path='/Form'>
            <BgBlur>
              <FormLink param={param} />
            </BgBlur>
          </Route>
        </Switch>
      </Router>
    </StyledApp>
  );
}

export default App;
