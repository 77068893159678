import {React, useState} from 'react';
import {ButtonsBlock, SmallBtn, VictorinContainer, VictorinText} from './styled';
import {VictorinItems} from '../../mocks/victorin-mocks';
import {Link} from 'react-router-dom';

const Victorin = () => {
    const data = VictorinItems;

    const [item, setItem] = useState(0);
    const [vissible, setVissible] = useState(true);

    const timeout = () => {
        setVissible(false);
        setTimeout(() => {setVissible(true)}, 1)

    }

    return (
        <VictorinContainer>
            <VictorinText vissible={vissible}> {data[item].text} </VictorinText>
            <ButtonsBlock>
                <Link to={'/form'}>
                    <SmallBtn>Sí</SmallBtn>
                </Link>
                {(item < 5) ? <SmallBtn onClick={() => {setItem(item + 1); timeout()}}>No</SmallBtn> : ''}

            </ButtonsBlock>
        </VictorinContainer>
    );
}

export default Victorin;
