import styled from 'styled-components';

export const StartContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 49px;
    text-align: center;
    align-items: center;
    margin-bottom: 130px;
    z-index: 2;

    @media (max-width: 768px) {
        padding: 41px 60px 0;
        gap: 46px;
    }
`;

export const StartText = styled.p`
    color: #ffffff;
    max-width: 425px;
    margin: 0;

    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;

    @media (max-width: 768px) {
        font-weight: 600;
        font-size: 25px;
        line-height: 28px;
        text-align: center;
    }

`;

export const BigBtn = styled.button`
    font-weight: 400;
    font-size: 40px;
    line-height: 45px;
    padding: 18px 55px 19px;
    background-color: #F0BB00;
    border: 0;
    border-radius: 16px;
    max-width: 335px;
    cursor: pointer;

    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;

    &:hover {
        background-color: #AA8500;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 22px;
        padding: 8px 19px;
    }
`;