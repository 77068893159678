import React from 'react';
import {BigBtn, StartContainer, StartText} from './styled';
import {Link} from 'react-router-dom';

const Start = () => {
    return (
        <StartContainer>
            <StartText>¿Quieres ser un bloguero popular?
                Completa el cuestionario
                y consigue una oferta única</StartText>
            <Link to={'/victorin'}>
                <BigBtn>Comenzar</BigBtn>
            </Link>
        </StartContainer>
    );
}

export default Start;
