import styled from 'styled-components';

export const VictorinContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 380px;
    justify-content: space-between;
    z-index: 2;

    @media (max-width: 768px) {
        padding: 40px 60px 0;
        height: 250px;
    }
`;

export const VictorinText = styled.p`
    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    display: ${(props) => props.vissible ? 'block' : 'none'};
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    color: #ffffff;
    max-width: 450px;
    text-align: center;
    margin: 0;
    animation: fadeIn 3s;

    @media (max-width: 768px) {
        font-size: 25px;
        line-height: 28px;
    }
`;

export const ButtonsBlock = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 21px;
    align-items: center;
`;

export const SmallBtn = styled.button`
    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }

    animation: fadeIn 3s;

    background-color: #F0BB00;
    border-radius: 16px;
    padding: 11px 49px;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    border: 0;
    min-width: 231px;
    cursor: pointer;

    &:hover {
        background-color: #AA8500;
    }

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 22px;
        min-width: 145px;
    }
`;